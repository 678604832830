/* @jsx jsx */
import { jsx } from "theme-ui"
import { Heading } from "gatsby-interface"
import HubspotForm from "../../../marketing-forms/HubspotForm"

export default ({ data }) => {
  return (
    <div
      css={theme => ({
        backgroundColor: theme.colors.purple[5],
        border: `1px solid rebeccapurple`,
        borderRadius: `12px`,
        padding: theme.space[5],
        margin: theme.space[5],

        [theme.mediaQueries.desktop]: {
          maxWidth: 1036,
          width: `100%`,
          margin: `0 auto`,
          transform: `translateY(-10rem)`,
          marginBottom: `-10rem`,
        },
      })}
    >
      <a name="sign-up" href="#sign-up" css={{ textDecoration: "none" }}>
        <Heading
          as="h2"
          tone="BRAND"
          sx={{
            fontSize: `2em`,
            fontWeight: 700,
            letterSpacing: `-1px`,
            textAlign: `center`,
            marginBottom: `0.5em`,
          }}
        >
          {
            data.content.find(block => block.__typename === "ContentfulHeading")
              .text.text
          }
        </Heading>
      </a>

      <div
        css={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <HubspotForm
          css={theme => ({
            "form.hs-form": {
              display: "flex",
              flexDirection: "column",

              [theme.mediaQueries.desktop]: {
                flexDirection: "row",
              },

              "& .form-columns-1": {
                "& .input": {
                  marginRight: "0!important",
                  width: "100%",
                },

                [theme.mediaQueries.desktop]: {
                  width: "400px",
                },
              },

              "& .form-columns-2": {
                "& .input": {
                  marginRight: "0!important",
                },

                [theme.mediaQueries.desktop]: {
                  "& .input": {
                    marginRight: "1rem!important",
                  },
                  width: "400px",
                },
              },

              "& .hs_submit": {
                marginTop: "1.2em",
              },
            },
          })}
          portalId="4731712"
          formId="3306602e-426b-47fd-b900-01a496af5f4e"
        />
      </div>
    </div>
  )
}
